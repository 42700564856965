import React, {PureComponent} from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import withStyles from '@mui/styles/withStyles';
import {UrlBuilder} from "../../../core/url/UrlBuilder";
import {Link as RouterLink} from "react-router-dom";
import Typography from "@mui/material/Typography";
import WorkoutCard from "../partials/WorkoutCard";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import Skeleton from '@mui/material/Skeleton';
import {UserLocalStorage} from "../../../core/storage/UserLocalStorage";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import InfiniteScroll from "react-infinite-scroll-component";
import EventIcon from '@mui/icons-material/Event';
import CalendarModule from "./CalendarModule";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import {pulse} from "react-animations";
import { styled } from '@mui/system';
import LastWeekWorkoutsCard from "../partials/LastWeekWorkoutsCard";
import {withRouter} from "../../../hooks/withRouter";
import {keyframes} from "@mui/material/styles";
import Button from "@mui/material/Button";
import GeneralStatsCard from "../partials/GeneralStatsCard";

const useStyles = theme => ({
	media: {
		height: 80,
	},
	largeIcon: {
		width: 160,
		height: 160,
	},
	floatRight: {
		float: 'right'
	},
	button: {
		minWidth: '30px',
		width: '30px',
		height: '30px',
		padding: '0',
	},
});

const pulseAnimation = keyframes`${pulse}`;
const PulseDiv = styled('div')(({ theme }) => ({
	animation: `2s ${pulseAnimation} infinite`
}));

class WorkoutsModule extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			workouts: UserLocalStorage.get(UrlBuilder.modules.workoutsModuleApi()) ?? null,
			isLoading: true,
			canFetchMoreWorkouts: false,
			isCalendarOpen: false,
		};
	}

	componentDidMount() {
		this.fetch();
	}

	fetch = async () => {
		this.setState({
			isLoading: true,
		});

		const response = await fetch(UrlBuilder.modules.workoutsModuleApi());

		if (response.status !== 200) {
			this.setState({
				isLoading: false,
			});
			return;
		}

		const responseData = await response.json();

		this.setState({
			workouts: responseData['data']['workouts'] ?? [],
			canFetchMoreWorkouts: responseData['data']['workouts'] && responseData['data']['workouts'].length >= 7, // Only stop when there is no more data.
			isLoading: false,
		});

		UserLocalStorage.set(UrlBuilder.modules.workoutsModuleApi(), responseData['data']['workouts'] ?? []);
	}

	fetchMore = async () => {
		const response = await fetch(UrlBuilder.modules.workoutsModuleApi(this.state.workouts.length));

		if (response.status !== 200) {
			return;
		}
		
		const responseData = await response.json();

		this.setState({
			workouts: this.state.workouts.concat(responseData['data']['workouts'] ?? []),
			canFetchMoreWorkouts: responseData['data']['workouts'] && responseData['data']['workouts'].length >= 7, // Only stop when there is no more data.
		});
	}
	
	toggleCalendar = (open) => {
		this.setState({
			isCalendarOpen: open,
		});
	}

	hasNeverBeenFetched = () => {
		return this.state.workouts === null;
	}

	renderNoWorkouts = () => {
		return <>
			<Grid container justifyContent="center">
				<Link component={RouterLink} to={UrlBuilder.routine.routineOverviewPage(true)} color="inherit">
					<PulseDiv>
						<IconButton
                            touch
                            tooltip="See routines"
                            onClick={this.toggleOpenAddRoutinePopup}
                            size="large">
							<AddToPhotosIcon sx={{width: 160, height: 160,}}/>
						</IconButton>
					</PulseDiv>
				</Link>
			</Grid>

			<Grid container justifyContent="center">
				<Typography variant="overline" gutterBottom>
					Create a Routine to Start a Workout
				</Typography>
			</Grid>

			<Grid container justifyContent="center">
				<Link component={RouterLink} to={UrlBuilder.routine.routineOverviewPage(true)} color="inherit">
					<Typography variant="overline">
						<strong>Go to your routines</strong>
					</Typography>
				</Link>
			</Grid>
		</>;
	}
	
	renderLoading = () => {
		const {classes} = this.props;

		return <>
			<Skeleton animation="pulse" variant="rectangular" style={{height: 275}} />

			<Box paddingTop={ 4 } />

			<Skeleton animation="pulse" variant="text" className={classes.media} />
			<br/>
			<Skeleton animation="wave" variant="rectangular" className={classes.media} />
			<br/>
			<Skeleton animation="wave" variant="rectangular" className={classes.media} />
			<br/>
			<Skeleton animation="wave" variant="rectangular" className={classes.media} />
		</>;
	}

	render() {
		if (this.hasNeverBeenFetched()) {
			return this.renderLoading();
		}

		const {classes} = this.props;

		return <>
			<LastWeekWorkoutsCard workouts={this.state.workouts}/>

			<Box paddingTop={ 2.5 } />

			<GeneralStatsCard/>

			<Box paddingTop={ 2.5 } />

			<Typography noWrap variant='h5'>
				Workout History: &nbsp;&nbsp;
				<Button 
					className={classes.floatRight}
					variant='contained' 
					onClick={() => this.toggleCalendar(true)} 
					size="medium"
				>
					<EventIcon/>
				</Button>
			</Typography>

			<CalendarModule isOpen={this.state.isCalendarOpen} closeSelfFunc={() => this.toggleCalendar(false)}/>

			<Box paddingTop={ 0.5 } />

			{this.state.isLoading ? 
				<LinearProgress value={20} style={{backgroundColor: 'rgba(255,255,255,0)'}}/> : 
				<Box paddingTop={0.5}/>
			}

			<Box paddingTop={ 1 } />
			
			{this.state.workouts.length === 0 ?
				this.renderNoWorkouts()
				:
				<InfiniteScroll
					dataLength={this.state.workouts.length}
					next={this.fetchMore}
					hasMore={this.state.canFetchMoreWorkouts}
					loader={<Box display="flex" justifyContent="center" alignItems="center" minHeight="10vh">
						<CircularProgress color="primary" />
					</Box>}
					style={{overflowX: 'hidden' }} /* Prevents horizontal scrolling */
				>
					{this.state.workouts.map(workout =>
						<React.Fragment key={workout.id}>
							<WorkoutCard workout={workout}/>
							<br/>
						</React.Fragment>
					)}
				</InfiniteScroll>
			}
		</>;
	}
}
export default withRouter(withStyles(useStyles)(WorkoutsModule))