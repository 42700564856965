import React, {Component} from 'react';
import withStyles from '@mui/styles/withStyles';
import WeightGoalCard from "./Goals/WeightGoalCard";
import Grid from "@mui/material/Grid";
import WeightRepsGoalCard from "./Goals/WeightRepsGoalCard";
import {LinearProgress, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import AddIcon from '@mui/icons-material/Add';
import Button from "@mui/material/Button";
import GoalNewOrEditFormPopUp from "./Goals/GoalNewOrEditFormPopUp";
import {UrlBuilder} from "../../../core/url/UrlBuilder";
import RepsGoalCard from "./Goals/RepsGoalCard";
import CardActionArea from "@mui/material/CardActionArea";
import DistanceTimeGoalCard from "./Goals/DistanceTimeGoalCard";
import TimeGoalCard from "./Goals/TimeGoalCard";
import WeightTimeGoalCard from "./Goals/WeightTimeGoalCard";
import Collapse from "@mui/material/Collapse";
import DistanceGoalCard from "./Goals/DistanceGoalCard";
import {ExerciseGoalType} from "../../../core/models/ExerciseGoalType";

const useStyles = theme => ({
	grow: {
		flexGrow: 1,
	},
	media: {
		height: 120,
	},
});

class RoutineExerciseDetailBloopGoalsTab extends Component {
	/**
	 * @param props Containing:
	 *      'exercise': The exercise you want to modify.
	 *      'isShowing': Is it showing?
	 *      'updateSwipeableViewsHeightFunc': Update parent height of view.
	 */
	constructor(props) {
		super(props);

		this.state = {
			/**
			 * The routine.
			 */
			routine: this.props.routine,
			/**
			 * The exercise that belongs to the routine.
			 */
			exercise: this.props.exercise,
			/**
			 * Is it showing?
			 */
			isShowing: false,
			/**
			 * Is the popup to edit/create a goal open?
			 */
			isGoalNewOrEditFormPopUpOpen: false,
			/**
			 * The goals to show (fetched from the API).
			 */
			goals: null,
			/**
			 * Is the fetching loading?
			 */
			isFetchingLoading: false,
		};
	}

	componentWillReceiveProps(nextProps, nextContext) {
		if (!nextProps.isShowing) {
			return;
		}
		
		this.setState({
			exercise: nextProps.exercise,
			goals: nextProps.exercise.routineExerciseId !== this.state.exercise.routineExerciseId ? null : this.state.goals,
			isShowing: nextProps.isShowing
		});
		
		this.refresh(nextProps.exercise);
	}

	shouldComponentUpdate(nextProps) {
		return nextProps.isShowing || nextProps.exercise.routineExerciseId !== this.state.exercise.routineExerciseId;
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		this.props.updateSwipeableViewsHeightFunc();
	}
	
	openGoalNewOrEditFormPopUp = (isOpen, goalToEdit = null, shouldRefresh = true) => {
		this.setState({
			isGoalNewOrEditFormPopUpOpen: isOpen,
			goalToEdit: goalToEdit,
		});
		if (!isOpen && shouldRefresh) {
			this.refresh();
		}
	}

	hasNeverBeenFetched = () => {
		return this.state.goals === null;
	}
	
	refresh = async (exercise = null) => {
		this.setState({
			isFetchingLoading: true
		});

		let goals = await this.fetchGoalsForExercise(exercise ? exercise : this.state.exercise);
		
		this.setState({
			goals: goals,
			isFetchingLoading: false,
		});
	}
	
	fetchGoalsForExercise = async(exercise) => {
		return await fetch(UrlBuilder.goals.exerciseGoalsApi(exercise.id, exercise.routineExerciseId))
			.then(response => response.json())
			.then(response => response['data'])
	}

	deleteGoal = async (event, goal) => {
		event.preventDefault(); // Prevents it from reloading the page and adding the params to the URL.

		await fetch(UrlBuilder.goals.goalsApi(goal.id), {
			method: 'DELETE',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
		})
			.then(() => this.openGoalNewOrEditFormPopUp(false, null, true));
	}
	
	renderGoalCard = (goal) => {
		switch (goal.type.id) {
			case ExerciseGoalType.WeightAndRepsGoal:
				return <WeightRepsGoalCard goal={goal} routine={this.state.routine} exercise={this.state.exercise}/>;
			case ExerciseGoalType.WeightGoal:
				return <WeightGoalCard goal={goal} routine={this.state.routine} exercise={this.state.exercise}/>;
			case ExerciseGoalType.RepsGoal:
				return <RepsGoalCard goal={goal} routine={this.state.routine} exercise={this.state.exercise}/>;
			case ExerciseGoalType.DistanceAndTimeGoal:
				return <DistanceTimeGoalCard goal={goal} routine={this.state.routine} exercise={this.state.exercise}/>
			case ExerciseGoalType.DistanceGoal:
				return <DistanceGoalCard goal={goal} routine={this.state.routine} exercise={this.state.exercise}/>;
			case ExerciseGoalType.TimeGoal:
				return <TimeGoalCard goal={goal} routine={this.state.routine} exercise={this.state.exercise}/>;
			case ExerciseGoalType.WeightAndTimeGoal:
				return <WeightTimeGoalCard goal={goal} routine={this.state.routine} exercise={this.state.exercise}/>;
		}
	}

	renderGoalCards = () => {
		if (this.hasNeverBeenFetched()) {
			return <React.Fragment/>;
		}

		if (this.state.goals.length === 0) {
			return <Box display="flex" justifyContent="center" alignItems="center">
				<Typography variant='overline'>
					No goals found.
				</Typography>
			</Box>
		}

		return <Grid container spacing={2}>
			{this.state.goals.map(goal =>
				<Grid item xs={12} key={goal.id} onClick={() => this.openGoalNewOrEditFormPopUp(true, goal)}>
					<CardActionArea>
						{this.renderGoalCard(goal)}
					</CardActionArea>
				</Grid>
			)}
		</Grid>
	}
	
	render() {
		const {classes} = this.props;

		if (!this.state.isShowing) {
			return <div/>;
		}

		return <>
			<GoalNewOrEditFormPopUp 
				exercise={this.state.exercise}
				goal={this.state.goalToEdit} 
				isOpen={this.state.isGoalNewOrEditFormPopUpOpen}
				deleteGoalFunc={this.deleteGoal}
				closeSelfFunc={(shouldRefresh) => this.openGoalNewOrEditFormPopUp(false, null, shouldRefresh)}
			/>

			<Box display="flex">
				<div className={classes.grow}/>
				<Button
					variant="contained"
					color="primary"
					size="small"
					onClick={() => this.openGoalNewOrEditFormPopUp(true)}
					startIcon={<AddIcon/>}
					disabled={this.state.routine.isArchived || this.state.exercise.isArchived}
				>
					New Goal
				</Button>
			</Box>

			<br/>

			<Collapse in={this.state.isFetchingLoading}>
				<LinearProgress/>
			</Collapse>

			{this.renderGoalCards()}
		</>
	}
}

export default withStyles(useStyles)(RoutineExerciseDetailBloopGoalsTab);