export class UrlBuilder {
    static redirect(link) {
        return '/redirect?link=' + encodeURIComponent(link);
    }
}

UrlBuilder.user = class {
    static settingsPage() {
        return '/settings';
    }
    
    static SignInPage() {
        return '/signin';
    }
    
    static SignUpPage() {
        return '/signup';
    }

    static statisticsApi(datetime = 'now') {
        return '/api/users/current/statistics/?date=' + datetime;
    }
    
    static SignUpAsGuestApi() {
        return '/api/users/signupGuest';
    }

    static convertGuestToUserApi() {
        return '/api/users/convertGuestToUser';
    }
    
    static currentUserApi() {
        return '/api/users/current'
    }

    static feedbackApi() {
        return '/api/feedback';
    }
    
    static privacyPage() {
        return '/privacy';
    }
};

UrlBuilder.modules = class {
    static calendarModuleApi(whichMonthYear) {
        return '/api/calendarModule?whichMonthYear=' + whichMonthYear;
    }
    
    static goalsModuleApi() {
        return '/api/goalsModule';
    }
    
    static HomeFabButtonApi() {
        return '/api/homeFabButton';
    }

    /**
     * 
     * @param skipAmount You can choose to skip this amount of first items. This helps in ajax calls.
     * @returns {string}
     */
    static workoutsModuleApi(skipAmount = null) {
        if (skipAmount !== null) {
            return '/api/workoutsModule?' + 'skip=' + skipAmount; // Grabs the next 7.
        }
        return '/api/workoutsModule'; // By default skips 0 and grabs 7
    }
};

UrlBuilder.exercise = class {
    static exercisesOverviewPage() {
        return '/exercises';
    }

    static exercisesApi(exerciseId = null) {
        if (exerciseId === null) {
            return 'api/exercises/';
        }
        return 'api/exercises/' + exerciseId;
    }

    static exerciseFormExamplesApi() {
            return 'api/exerciseFormExamples';
    }
    
    static allExerciseFormExamplesApi() {
        return 'api/exerciseFormExamples/all';
    }
};

UrlBuilder.me = class {
    static mePage() {
        return '/me';
    }
};

UrlBuilder.donations = class {
    static donationsApi() {
        return '/api/donations';
    }
};

UrlBuilder.playPayments = class {
    static payment(productName) {
        return 'api/playPayments/' + productName;
    }

    static acknowledge(productName, purchaseToken, displayName) {
        return 'api/playPayments/acknowledge/' + productName + '?purchaseToken=' + purchaseToken + '&displayName=' + displayName;
    }
}

UrlBuilder.graphql = 'graphql';

UrlBuilder.admin = class {
    static overviewPage() {
        return '/admin';
    }
}

UrlBuilder.measurements = class {
    static measurementsApi(measurementId = null) {
        if (measurementId) {
            return '/api/measurements/' + measurementId;
        }
        return '/api/measurements';
    }

    static measurementsBulkApi() {
        return '/api/measurements/bulk';
    }
    
    static measurementLogsApi(measurementLogId = null) {
        if (measurementLogId) {
            return '/api/measurementLogs/' + measurementLogId;
        }
        return '/api/measurementLogs';
    }
};

UrlBuilder.workout = class {
    static workoutDetailPage(workoutId = null, includeWorkoutDoneMessage = false) {
        if (workoutId === null) {
            return '/workouts/:workoutId';
        }
        if (includeWorkoutDoneMessage) {
            return '/workouts/' + workoutId + '?just_finished' ;
        }
        return '/workouts/' + workoutId;
    }

    static workoutsApi(workoutId = null, daysAgo = null) {
        if (workoutId === null) {
            if (daysAgo !== null) {
                return 'api/workouts?daysAgo=' + daysAgo;
            }
            return 'api/workouts/';
        }
        return 'api/workouts/' + workoutId;
    }
    
    static startWorkoutApi() {
        return 'api/users/current/workouts/start';
    }
    
    static finishWorkoutApi() {
        return 'api/users/current/workouts/finish';
    }
    
    static currentWorkoutApi() {
        return 'api/users/current/workouts/current'
    }

    static workoutsForRoutineApi(routineId, from = null, to = null) {
        let url = 'api/routines/' + routineId + '/workouts';

        if (from !== null && to !== null) {
            url += '?from=' + from + '&to=' + to;
        } else if (from !== null) {
            url += '?from=' + from;
        } else if (to !== null) {
            url += '?to=' + to;
        }
        
        return url;
    }
};


UrlBuilder.routine = class {
    //Pages
    
    static routineOverviewPage(shouldOpenAddRoutine = false) {
        if (shouldOpenAddRoutine) {
            return 'routines?add=true';
        }
        
        return '/routines';
    }

    static routineDetailPage(routineId = null, routineExerciseId = null) {
        if (routineId === null && routineExerciseId === null) {
            return '/routines/:routineId';
        }

        if (routineExerciseId !== null) {
            return '/routines/' + routineId + '?exercise=' + routineExerciseId;
        }

        return '/routines/' + routineId;
    }

    
    //Apis
    
    static routinesApi(routineId = null, simple = false, archivedMode = false, datetime = null) {
        if (routineId !== null) {
            if (archivedMode) {
                return '/api/routines/' + routineId + '?archivedMode=true' + (datetime ? '&time=' + datetime : '');
            }
            return '/api/routines/' + routineId + (datetime ? '?time=' + datetime : '');
        }
        if (simple) {
            return '/api/routines?simple=true';
        }
        return '/api/routines/';
    }

    static routinesBulkApi() {
        return '/api/routines/bulk';
    }
    
    static archivedRoutinesApi() {
        return '/api/routines/archived';
    }

    static routineExercisesApi(routineExerciseId = null, time = null, includeAllLogs = null) {
        if (routineExerciseId !== null) {
            if (time !== null) {
                return '/api/routineExercises/' + routineExerciseId + '?time=' + time + (includeAllLogs ? '&includeAll=true' : '');
            }
            return '/api/routineExercises/' + routineExerciseId + (includeAllLogs ? '?includeAll=true' : '');
        }
        return '/api/routineExercises/';
    }

    static routineExerciseLogsApi(logId = null) {
        if (logId !== null) {
            return '/api/routineExerciseLogs/' + logId;
        }
        return '/api/routineExerciseLogs/';
    }

    static routineExercisePlannedLogsApi(routineExerciseId = null) {
        if (routineExerciseId !== null) {
            return '/api/routineExercisePlannedLogs/' + routineExerciseId;
        }
        return '/api/routineExercisePlannedLogs/';
    }
    
    static supersetsApi(routineId = null) {
        if (routineId !== null) {
            return '/api/supersets/' + routineId;
        }
        return '/api/supersets/';
    }
};

UrlBuilder.goals = class {
    static exerciseGoalsApi(exerciseId = null, routineExerciseId = null) {
        if (exerciseId !== null && routineExerciseId !== null) {
            return 'api/exerciseGoals/' + exerciseId + '?routineExerciseId=' + routineExerciseId;
        }
        return 'api/exerciseGoals/';
    }

    static goalsApi(goalId) {
        return 'api/exerciseGoals/' + goalId;
    }
}